import React from "react";
import ReactDOM from "react-dom/client";
import Aos from "aos";
import "../src/assets/scss/fontello.css";
import "aos/dist/aos.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store/config.store";
import { PersistGate } from "redux-persist/integration/react";
import persistStore from "redux-persist/es/persistStore";
import "react-lazy-load-image-component/src/effects/blur.css";
import 'bootstrap/dist/css/bootstrap.min.css'; 


const persistor = persistStore(store);

Aos.init({
  duration: 1000,
  offset: 120,
  easing: "ease-in-out",
});
document.body.removeAttribute('data-aos-easing');
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js')
      .then((reg) => console.log('Service Worker registered!', reg))
      .catch((err) => console.log('Service Worker registration failed:', err));
  });
}
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
